import Splide from '@splidejs/splide';
import { createApp } from 'petite-vue';

export default () => {
  const popupComponents = document.querySelectorAll('[popup="component"]');

  popupComponents.forEach((popupComponent) => {
    createApp({
      mulutiImages: [],
      getImageSources(componentEl) {
        document.body.addEventListener('htmx:afterSwap', (evt) => {
          const htmxPath = evt.detail.pathInfo.finalRequestPath;
          const componentSlug = `portfolio/${componentEl.getAttribute('component-slug')}`;

          if (htmxPath === componentSlug) {
            const images = componentEl.querySelectorAll('[popup="multi-image-sources"]');

            images.forEach((image) => {
              this.mulutiImages.push({ src: image.src, srcset: image.srcset });
            });
          }
        });
      },
    }).mount(popupComponent);

    const trigger = popupComponent.querySelector('[popup="trigger"]');
    const popup = popupComponent.querySelector('[popup="popup"]');
    const closeTrigger = popupComponent.querySelectorAll('[popup="close"]');

    const mainSlideElement = popup.querySelector('.popup_main-splide.splide');
    const subSlideElement = popup.querySelector('.popup_sub-splide.splide');

    const mainSlide = new Splide(mainSlideElement, {
      type: 'loop',
      perPage: 1,
      perMove: 1,
      pagination: false,
      arrows: true,
    });

    const subSplide = new Splide(subSlideElement, {
      type: 'slide',
      fixedWidth: 121,
      fixedHeight: 126,
      rewind: true,
      isNavigation: true,
      gap: '1rem',
      pagination: false,
      arrows: false,
    });

    const openPopup = () => {
      const popupState = popupComponent.getAttribute('state');

      if (popupState === 'closed') {
        popup.style.display = 'flex';

        mainSlide.sync(subSplide);
        mainSlide.mount();
        subSplide.mount();

        popupComponent.setAttribute('state', 'open');
      }
    };

    const closePopup = () => {
      const popupState = popupComponent.getAttribute('state');

      if (popupState === 'open') {
        popup.style.display = 'none';

        mainSlide.destroy();
        subSplide.destroy();

        popupComponent.setAttribute('state', 'closed');
      }
    };

    trigger.addEventListener('click', openPopup);

    closeTrigger.forEach((close) => {
      close.addEventListener('click', closePopup);
    });
  });
};
