import Navigo from 'navigo';

import homeRoute from './Routes/home';

import Popup from './Components/Popup/Popup';

const router = new Navigo('/');

router.on('/', () => {
  homeRoute();
  Popup();
});

router.on('/popup-component', () => {
  Popup();
});

router.notFound(() => true);

router.resolve();
