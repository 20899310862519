import Splide from '@splidejs/splide';

export default () => {
  new Splide('.home-testimonials-splide', {
    type: 'loop',
    perPage: 1,
    perMove: 1,
    pagination: false,
    arrows: true,
  }).mount();

  new Splide('.splide.home-guests_slider', {
    type: 'loop',
    perPage: 2,
    focus: 'center',
    perMove: 1,
    pagination: false,
    arrows: true,
    gap: '1.5rem',
    height: '100%',
  }).mount();
};
